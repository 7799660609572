import React from "react"
import ViewBillSidebar from "components/page-layout/sidebar/ViewBillSidebar"

import PERMISSION_CODES from "./permission-codes"

export const ROUTE_HOMEPAGE = "/"
export const ROUTE_LOGIN = "/log-in"
export const ROUTE_CUSTOMER_ROOT = "/customers"
export const ROUTE_COMPANY_INFORMATION_ROOT = "/company-information"
export const ROUTE_USER_ROOT = "/users"
export const ROUTE_SECTION_ROOT = "/sections"
export const ROUTE_CURRENCY_ROOT = "/currencies"
export const ROUTE_PAYMENT_METHODS_ROOT = "/payment-methods"
export const ROUTE_ROLE_ROOT = "/roles"
export const ROUTE_MASTER_DATA_PROCESS = "/process"
export const ROUTE_MASTER_DATA_MATERIAL = "/material"
export const ROUTE_VENDOR_ROOT = "/vendor"
export const ROUTE_INQUIRY_ROOT = "/inquiries"
export const ROUTE_SALES_QUOT_ROOT = "/sales-quotation"
export const ROUTE_ESTQUOT_ROOT = "/estimation-quotation"
export const ROUTE_BILL_OF_MATERIAL_ROOT = "/bill-of-material"
export const ROUTE_PURCHASE_ORDER_ROOT = "/purchase-order"
export const ROUTE_GOODS_MOVEMENT_ROOT = "/goods-movement"
export const ROUTE_PROJECTS = "/projects"
export const ROUTE_WORK_TIME_REPORT = "/work-time-report"
export const ROUTE_DAILY_TIME_REPORT = `${ROUTE_WORK_TIME_REPORT}/daily`
export const ROUTE_SUMMARY_DAILY_TIME_REPORT = `${ROUTE_WORK_TIME_REPORT}/summary/machine`
export const ROUTE_SUMMARY_DAILY_TIME_REPORT_MANPOWER = `${ROUTE_WORK_TIME_REPORT}/summary/man-power`
export const ROUTE_SUMMARY_DAILY_TIME_REPORT_PROJECT = `${ROUTE_WORK_TIME_REPORT}/summary/project`
export const ROUTE_WEEKLY_TIME_REPORT = `${ROUTE_WORK_TIME_REPORT}/weekly`
export const ROUTE_MONTHLY_TIME_REPORT = `${ROUTE_WORK_TIME_REPORT}/monthly`
export const ROUTE_ANNUAL_TIME_REPORT = `${ROUTE_WORK_TIME_REPORT}/annual`
export const ROUTE_MASTER_DATA_MACHINE = "/machine"
export const ROUTE_INCOMING_MATERIAL = "/incoming-material"
export const ROUTE_OUTGOING_MATERIAL = "/outgoing-material"
export const ROUTE_PURCHASE_REQUISITION_ROOT = "/purchase-requisition"
export const ROUTE_STOCK_ADJUSTMENT = "/stock-adjustment"
export const ROUTE_DELIVERY_NOTE = "/delivery-note"
export const ROUTE_STOCK_MONITORING = "/stock-monitoring"
export const ROUTE_MATERIAL_TRANSACTION = "/material-transaction"
export const ROUTE_REQUEST_FOR_QUOTATION = "/request-for-quotation"
export const ROUTE_WORK_ORDER = "/work-order"
export const ROUTE_MASTER_DATA_MATERIAL_TYPE = "/material-type"
export const ROUTE_CUT_OFF = "/cut-off"
export const ROUTE_INVOICE_ROOT = "/invoice"
export const ROUTE_GOODS_RECEIPT_ROOT = "/good-receipts"
export const ROUTE_GOODS_ISSUE_ROOT = "/good-issues"
export const ROUTE_REPORT_AP = "/report/account-payable"
export const ROUTE_REPORT_AR = "/report/account-receivable"
export const ROUTE_REPORT_WTR_MONTHLY = "/report/wtr/monthly"
export const ROUTE_REPORT_WTR_OPERATOR = "/report/wtr/operator"
export const ROUTE_REPORT_WTR_MACHINE = "/report/wtr/machine"
export const ROUTE_REPORT_PRODUCT_CHECK_BY_QC = "/report/product-checked-by-qc"
export const ROUTE_REPORT_MOULD_CODE = "/report/mould-code"
export const ROUTE_REPORT_PURCHASING = "/report/purchasing"
export const ROUTE_REPORT_SQ_OUTSTANDING = "/report/quotation-outstanding"
export const ROUTE_REPORT_MARKETING_PROJECT = "/report/marketing-project"
export const ROUTE_DELIVERY_NOTE_SUPPLIER = "/delivery-note-supplier"
export const ROUTE_PO_CUSTOMER_REPORT = "/report/po-customer"
export const ROUTE_PROJECT_PROFIT_REPORT = "/report/projects/profit"
export const ROUTE_PROJECT_HOUR_REPORT = "/report/projects/hour"
export const ROUTE_PURCHASING_USAGE_REPORT = "/report/purchasing/usage"
export const ROUTE_INVOICE_SUPPLIER_ROOT = "/invoice-supplier"
export const ROUTE_COVER_WORKING_TIME_REPORT = "/report/working-time/cover"
export const ROUTE_GOODS_RECEIPT_TRACKING_REPORT =
  "/report/goods-receipt/tracking"
export const ROUTE_MANUFACTURING_PROGRESS = "/manufacturing-progress"
export const ROUTE_SALES_REPORT = "/report/sales"
export const ROUTE_MANUFACTURING_PROGRESS_REPORT =
  "/report/manufacturing-progress"
export const ROUTE_MANUFACTURING_PLANNING = "/manufacturing-planning"
export const ROUTE_MANUFACTURING_PLANNING_REPORT =
  "/report/manufacturing-planning"

export const ROUTE_MANUFACTURING_PLANNING_REPORT_ITEM =
  "/report/manufacturing-planning/item"

export const ROUTE_BILL_OF_MATERIAL_COST_REPORT =
  "/report/bill-of-material/costs"

export const ROUTE_MANUFACTURING_PROCESS_ROOT = "/manufacturing-process"

export const ROUTE_COMPILATION_PROJECT_PROFIT =
  "/report/projects/profit/compilation"

export const ROUTE_TRUCK_UTILITY = "/truck-utility"
export const ROUTE_SALES_QUOTATION_LIST_REPORT = "/report/sales-quotation-list"

export const DRAWER_ROUTES = [
  {
    name: "Dashboard",
    routes: [
      {
        name: "Dashboard",
        link: ROUTE_HOMEPAGE,
      },
    ],
  },
  {
    name: "Report",
    routes: [
      {
        name: "Account Receivable (AR)",
        link: ROUTE_REPORT_AR,
        permissions: [PERMISSION_CODES.READ_ACCOUNT_RECEIVABLE_DASHBOARD],
      },
      {
        name: "Account Payable (AP)",
        link: ROUTE_REPORT_AP,
        permissions: [PERMISSION_CODES.READ_ACCOUNT_PAYABLE_DASHBOARD],
      },
      {
        name: "Working Time Report (monthly)",
        link: ROUTE_REPORT_WTR_MONTHLY,
        permissions: [PERMISSION_CODES.READ_WTR_MONTHLY_DASHBOARD],
      },
      {
        name: "Working Time Report (Operator)",
        link: ROUTE_REPORT_WTR_OPERATOR,
        permissions: [PERMISSION_CODES.READ_WTR_OPERATOR_DASHBOARD],
      },
      {
        name: "Working Time Report (Machine)",
        link: ROUTE_REPORT_WTR_MACHINE,
        permissions: [PERMISSION_CODES.READ_WTR_MACHINE_DASHBOARD],
      },
      {
        name: "Cover WT Control",
        link: ROUTE_COVER_WORKING_TIME_REPORT,
        permissions: [PERMISSION_CODES.READ_COVER_WORKING_TIME_DASHBOARD],
      },
      // {
      //   name: "Product Checked by QC",
      //   link: ROUTE_REPORT_PRODUCT_CHECK_BY_QC,
      //   permissions: [PERMISSION_CODES.WRITE_DELIVERY_NOTES],
      // },
      {
        name: "Project Tracking",
        link: ROUTE_REPORT_MOULD_CODE,
        permissions: [PERMISSION_CODES.READ_MOULD_CODE_REPORT_DASHBOARD],
      },
      {
        name: "Purchasing Report",
        link: ROUTE_REPORT_PURCHASING,
        permissions: [PERMISSION_CODES.READ_PURCHASING_REPORT_DASHBOARD],
      },
      {
        name: "Quotation PO Outstanding",
        link: ROUTE_REPORT_SQ_OUTSTANDING,
        permissions: [
          PERMISSION_CODES.READ_SALES_QUOTATION_OUTSTANDING_DASHBOARD,
        ],
      },
      {
        name: "Sales Report",
        link: ROUTE_REPORT_MARKETING_PROJECT,
        permissions: [PERMISSION_CODES.READ_MARKETING_PROJECT_REPORT_DASHBOARD],
      },
      {
        name: "PO. Customer",
        link: ROUTE_PO_CUSTOMER_REPORT,
        permissions: [PERMISSION_CODES.READ_PO_CUSTOMER_DASHBOARD],
      },
      {
        name: "Compilation of Project Profit",
        link: ROUTE_COMPILATION_PROJECT_PROFIT,
        permissions: [PERMISSION_CODES.READ_COMPILATION_OF_PROJECT_PROFIT],
      },
      {
        name: "Project Profit",
        link: ROUTE_PROJECT_PROFIT_REPORT,
        permissions: [PERMISSION_CODES.READ_PROJECT_PROFIT_DASHBOARD],
      },
      {
        name: "Project Hour",
        link: ROUTE_PROJECT_HOUR_REPORT,
        permissions: [PERMISSION_CODES.READ_PROJECT_HOURS_DASHBOARD],
      },
      {
        name: "Purchasing Usage",
        link: ROUTE_PURCHASING_USAGE_REPORT,
        permissions: [PERMISSION_CODES.READ_PURCHASING_USAGE_REPORT],
      },

      {
        name: "Goods Receipt Tracking",
        link: ROUTE_GOODS_RECEIPT_TRACKING_REPORT,
        permissions: [PERMISSION_CODES.READ_GOODS_RECEIPT_TRACKING_REPORT],
      },
      {
        name: "Manufacturing Progress",
        link: ROUTE_MANUFACTURING_PROGRESS_REPORT,
        permissions: [PERMISSION_CODES.READ_MANUFACTURING_PROGRESS_REPORT],
      },
      {
        name: "Manufacturing Planning Report",
        link: ROUTE_MANUFACTURING_PLANNING_REPORT,
        permissions: [PERMISSION_CODES.READ_MANUFACTURING_PLANNING_REPORT],
      },
      {
        name: "Manufacturing Planning Report Item",
        link: ROUTE_MANUFACTURING_PLANNING_REPORT_ITEM,
        permissions: [PERMISSION_CODES.READ_MANUFACTURING_PLANNING_REPORT],
      },
      {
        name: "Bill Of Material Costs",
        link: ROUTE_BILL_OF_MATERIAL_COST_REPORT,
        permissions: [PERMISSION_CODES.READ_BILL_OF_MATERIAL_COST_DASHBOARD],
      },
      {
        name: "Sales Quotation List",
        link: ROUTE_SALES_QUOTATION_LIST_REPORT,
        permissions: [PERMISSION_CODES.READ_SALES_QUOTATION_LIST_REPORT],
      },
    ],
  },
  {
    name: "Master Data",
    routes: [
      {
        name: "Company Information",
        routes: [
          {
            name: "View Company Information",
            link: ROUTE_COMPANY_INFORMATION_ROOT,
            permissions: [PERMISSION_CODES.READ_COMPANY_INFORMATION_PAGES],
          },
        ],
      },
      {
        name: "User",
        routes: [
          {
            name: "View List User",
            link: ROUTE_USER_ROOT,
            permissions: [PERMISSION_CODES.READ_EMPLOYEE],
          },
          {
            name: "Create User",
            link: `${ROUTE_USER_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_USER],
          },
        ],
      },
      {
        name: "Section",
        routes: [
          {
            name: "View Section",
            link: ROUTE_SECTION_ROOT,
            permissions: [PERMISSION_CODES.READ_SECTION_PAGES],
          },
          {
            name: "Create Section",
            link: `${ROUTE_SECTION_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_SECTION],
          },
        ],
      },
      {
        name: "Customer",
        routes: [
          {
            name: "View List Customer",
            link: ROUTE_CUSTOMER_ROOT,
            permissions: [PERMISSION_CODES.READ_CUSTOMER_PAGES],
          },
          {
            name: "Create Customer",
            link: `${ROUTE_CUSTOMER_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_CUSTOMER],
          },
        ],
      },
      {
        name: "Vendor",
        routes: [
          {
            name: "View List Vendor",
            link: ROUTE_VENDOR_ROOT,
            permissions: [PERMISSION_CODES.READ_VENDOR_PAGES],
          },
          {
            name: "Create Vendor",
            link: `${ROUTE_VENDOR_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_VENDOR],
          },
        ],
      },
      {
        name: "Currency",
        routes: [
          {
            name: "View List Currency",
            link: ROUTE_CURRENCY_ROOT,
            permissions: [PERMISSION_CODES.READ_CURRENCY_PAGES],
          },
          {
            name: "Create Currency",
            link: `${ROUTE_CURRENCY_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_CURRENCY],
          },
        ],
      },
      {
        name: "Payment Method",
        routes: [
          {
            name: "View List Payment Method",
            link: ROUTE_PAYMENT_METHODS_ROOT,
            permissions: [PERMISSION_CODES.READ_PAYMENT_METHOD_PAGES],
          },
          {
            name: "Create Payment Method",
            link: `${ROUTE_PAYMENT_METHODS_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_PAYMENT_METHOD],
          },
        ],
      },
      {
        name: "Role",
        routes: [
          {
            name: "View List Role",
            link: ROUTE_ROLE_ROOT,
            permissions: [PERMISSION_CODES.READ_ROLE_PAGES],
          },
          {
            name: "Create Role",
            link: `${ROUTE_ROLE_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_ROLES],
          },
        ],
      },
      {
        name: "Process",
        routes: [
          {
            name: "View List Process",
            link: ROUTE_MASTER_DATA_PROCESS,
            permissions: [PERMISSION_CODES.READ_PROCESS_PAGES],
          },
          {
            name: "Create Process",
            link: `${ROUTE_MASTER_DATA_PROCESS}/create`,
            permissions: [PERMISSION_CODES.WRITE_PROCESS],
          },
        ],
      },
      {
        name: "Material",
        routes: [
          {
            name: "View List Material",
            link: ROUTE_MASTER_DATA_MATERIAL,
            permissions: [PERMISSION_CODES.READ_MATERIAL_PAGES],
          },
          {
            name: "Create Material",
            link: `${ROUTE_MASTER_DATA_MATERIAL}/create`,
            permissions: [PERMISSION_CODES.WRITE_MATERIAL],
          },
          {
            name: "View List Material Type",
            link: ROUTE_MASTER_DATA_MATERIAL_TYPE,
            permissions: [PERMISSION_CODES.READ_MATERIAL_TYPE],
          },
          {
            name: "Create Material Type",
            link: `${ROUTE_MASTER_DATA_MATERIAL_TYPE}/create`,
            permissions: [PERMISSION_CODES.WRITE_MATERIAL_TYPE],
          },
        ],
      },
      {
        name: "Machine",
        routes: [
          {
            name: "View List Machine",
            link: ROUTE_MASTER_DATA_MACHINE,
            permissions: [PERMISSION_CODES.READ_MACHINE_PAGES],
          },
          {
            name: "Create Machine",
            link: `${ROUTE_MASTER_DATA_MACHINE}/create`,
            permissions: [PERMISSION_CODES.WRITE_MACHINE],
          },
        ],
      },
      {
        name: "Manufacturing Process",
        routes: [
          {
            name: "View List Manufacturing Process",
            link: ROUTE_MANUFACTURING_PROCESS_ROOT,
            permissions: [PERMISSION_CODES.READ_MANUFACTURING_PROCESS],
          },
          {
            name: "Create Manufacturing Process",
            link: `${ROUTE_MANUFACTURING_PROCESS_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_MANUFACTURING_PROCESS],
          },
        ],
      },
      {
        name: "Truck Utility",
        routes: [
          {
            name: "View List Truck Utility",
            link: ROUTE_TRUCK_UTILITY,
            permissions: [PERMISSION_CODES.READ_TRUCK_UTILITY],
          },
          {
            name: "Create Truck Utility",
            link: `${ROUTE_TRUCK_UTILITY}/create`,
            permissions: [PERMISSION_CODES.WRITE_TRUCK_UTILITY],
          },
        ],
      },
    ],
  },
  {
    name: "Sales & Distribution",
    routes: [
      {
        name: "Inquiry & Quotation",
        routes: [
          {
            name: "RFQ",
            link: `${ROUTE_REQUEST_FOR_QUOTATION}/create`,
            permissions: [PERMISSION_CODES.WRITE_REQUEST_FOR_QUOTATION],
          },
          {
            name: "View List RFQ",
            link: ROUTE_REQUEST_FOR_QUOTATION,
            permissions: [PERMISSION_CODES.READ_REQUEST_FOR_QUOTATION_PAGES],
          },
          {
            name: "View Sales Quotation",
            link: ROUTE_SALES_QUOT_ROOT,
            permissions: [PERMISSION_CODES.READ_SALES_FOR_QUOTATION_PAGES],
          },
          {
            name: "Create Sales Quotation",
            link: `${ROUTE_SALES_QUOT_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_SALES_FOR_QUOTATION],
          },
          {
            name: "View Work Order",
            link: ROUTE_WORK_ORDER,
            permissions: [PERMISSION_CODES.READ_WORK_ORDER],
          },
          {
            name: "Create Work Order",
            link: `${ROUTE_WORK_ORDER}/create`,
            permissions: [PERMISSION_CODES.WRITE_WORK_ORDER],
          },
        ],
      },
      {
        name: "Invoice",
        routes: [
          {
            name: "View Invoice",
            link: ROUTE_INVOICE_ROOT,
            permissions: [PERMISSION_CODES.READ_INVOICE],
          },
          {
            name: "Create Invoice",
            link: `${ROUTE_INVOICE_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_INVOICE],
          },
        ],
      },
    ],
  },
  {
    name: "Material Management",
    routes: [
      {
        name: "Inventory Control",
        routes: [
          {
            name: "Goods Receipt",
            link: ROUTE_GOODS_RECEIPT_ROOT,
            permissions: [PERMISSION_CODES.READ_GOODS_MOVEMENT],
          },
          {
            name: "Goods Issue",
            link: ROUTE_GOODS_ISSUE_ROOT,
            permissions: [PERMISSION_CODES.READ_GOODS_MOVEMENT],
          },
          {
            name: "Goods Movement",
            link: ROUTE_GOODS_MOVEMENT_ROOT,
            permissions: [PERMISSION_CODES.READ_GOODS_MOVEMENT],
          },
        ],
      },
      {
        name: "Delivery Note",
        routes: [
          {
            name: "View Delivery Note",
            link: ROUTE_DELIVERY_NOTE,
            permissions: [PERMISSION_CODES.READ_DELIVERY_NOTES],
          },
          {
            name: "Create Delivery Note",
            link: `${ROUTE_DELIVERY_NOTE}/create`,
            permissions: [PERMISSION_CODES.WRITE_DELIVERY_NOTES],
          },
          {
            name: "View Delivery Note Supplier",
            link: ROUTE_DELIVERY_NOTE_SUPPLIER,
            permissions: [PERMISSION_CODES.READ_DELIVERY_NOTES_SUPPLIER],
          },
          {
            name: "Create Delivery Note Supplier",
            link: `${ROUTE_DELIVERY_NOTE_SUPPLIER}/create`,
            permissions: [PERMISSION_CODES.WRITE_DELIVERY_NOTES_SUPPLIER],
          },
        ],
      },
      {
        name: "Report Stock Monitoring & Control",
        routes: [
          {
            name: "View Report Stock Monitoring & Control",
            link: ROUTE_STOCK_MONITORING,
            permissions: [PERMISSION_CODES.READ_STOCK_MONITORING],
          },
          {
            name: "Cut Off Stock",
            link: ROUTE_CUT_OFF,
            permissions: [PERMISSION_CODES.READ_CUT_OFF],
          },
        ],
      },
      {
        name: "Stock Adjustment",
        routes: [
          {
            name: "Create Stock Adjustment",
            link: `${ROUTE_STOCK_ADJUSTMENT}/create`,
            permissions: [PERMISSION_CODES.CREATE_STOCK_ADJUSTMENT],
          },
          {
            name: "View List Stock Adjustment",
            link: ROUTE_STOCK_ADJUSTMENT,
            permissions: [PERMISSION_CODES.READ_STOCK_ADJUSTMENT],
          },
        ],
      },
      {
        name: "Material Transaction",
        link: ROUTE_MATERIAL_TRANSACTION,
        permissions: [PERMISSION_CODES.READ_MATERIAL_TRANSACTION],
      },
    ],
  },
  {
    name: "Design Engineering",
    routes: [
      {
        name: "Bill of Material",
        routes: [
          {
            name: <ViewBillSidebar />,
            link: ROUTE_BILL_OF_MATERIAL_ROOT,
            permissions: [PERMISSION_CODES.READ_BILL_OF_MATERIAL_PAGES],
          },
          {
            name: "Create New Bill",
            link: `${ROUTE_BILL_OF_MATERIAL_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_BILL_OF_MATERIAL],
          },
        ],
      },
    ],
  },
  {
    name: "Purchasing",
    routes: [
      {
        name: "Purchase Order",
        routes: [
          {
            name: "View Orders",
            link: ROUTE_PURCHASE_ORDER_ROOT,
            permissions: [PERMISSION_CODES.READ_PURCHASE_ORDER_PAGES],
          },
          {
            name: "Create New Purchase Order",
            link: `${ROUTE_PURCHASE_ORDER_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_PURCHASE_ORDER],
          },
        ],
      },
      {
        name: "Purchase Requisition",
        routes: [
          {
            name: "View Purchase Requisition List",
            link: ROUTE_PURCHASE_REQUISITION_ROOT,
            permissions: [PERMISSION_CODES.READ_PURCHASE_REQUISITION],
          },
          {
            name: "Create Purchase Requisition",
            link: `${ROUTE_PURCHASE_REQUISITION_ROOT}/create`,
            permissions: [PERMISSION_CODES.CREATE_PURCHASE_REQUISITION],
          },
          {
            name: "Approval",
            link: `${ROUTE_PURCHASE_REQUISITION_ROOT}/approval`,
            permissions: [PERMISSION_CODES.APPROVE_PURCHASE_REQUISITION],
          },
        ],
      },
      {
        name: "Invoice",
        routes: [
          {
            name: "View Invoice Supplier",
            link: ROUTE_INVOICE_SUPPLIER_ROOT,
            permissions: [PERMISSION_CODES.READ_INVOICE_SUPPLIER],
          },
          {
            name: "Create Invoice Supplier",
            link: `${ROUTE_INVOICE_SUPPLIER_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_INVOICE_SUPPLIER],
          },
        ],
      },
    ],
  },
  {
    name: "Production Planning",
    routes: [
      {
        name: "Estimation Quotations",
        routes: [
          {
            name: "List Estimation Quotations",
            link: ROUTE_ESTQUOT_ROOT,
            permissions: [PERMISSION_CODES.READ_ESTIMATE_FOR_QUOTATION_PAGES],
          },
          {
            name: "Create New Estimation",
            link: `${ROUTE_ESTQUOT_ROOT}/create`,
            permissions: [PERMISSION_CODES.WRITE_ESTIMATE_FOR_QUOTATION],
          },
        ],
      },
      {
        name: "Project",
        routes: [
          {
            name: "View Project",
            link: ROUTE_PROJECTS,
            permissions: [PERMISSION_CODES.READ_PROJECTS],
          },
        ],
      },
      {
        name: "Manufacturing Progress",
        routes: [
          {
            name: "View Manufacturing Progress",
            link: ROUTE_MANUFACTURING_PROGRESS,
            permissions: [PERMISSION_CODES.READ_MANUFACTURING_PROGRESS],
          },
          {
            name: "Create Manufacturing Progress",
            link: `${ROUTE_MANUFACTURING_PROGRESS}/create`,
            permissions: [PERMISSION_CODES.WRITE_MANUFACTURING_PROGRESS],
          },
        ],
      },
      {
        name: "Work Time Report",
        routes: [
          {
            name: "Daily Production Report",
            link: ROUTE_DAILY_TIME_REPORT,
            permissions: [PERMISSION_CODES.READ_DAILY_REPORT],
          },
          // {
          //   name: "Create Daily Production Report",
          //   link: `${ROUTE_DAILY_TIME_REPORT}/create`,
          //   permissions: [PERMISSION_CODES.WRITE_DAILY_REPORT],
          // },
          {
            name: "Daily Production Summary (Machine)",
            link: `${ROUTE_SUMMARY_DAILY_TIME_REPORT}`,
            permissions: [PERMISSION_CODES.READ_DAILY_REPORT],
          },
          {
            name: "Daily Production Summary (Project)",
            link: `${ROUTE_SUMMARY_DAILY_TIME_REPORT_PROJECT}`,
            permissions: [PERMISSION_CODES.READ_DAILY_REPORT],
          },
          // {
          //   name: "Daily Production Summary (Man-power)",
          //   link: `${ROUTE_SUMMARY_DAILY_TIME_REPORT_MANPOWER}`,
          //   permissions: [PERMISSION_CODES.READ_DAILY_REPORT],
          // },
          {
            name: "View Weekly Report",
            link: ROUTE_WEEKLY_TIME_REPORT,
            permissions: [PERMISSION_CODES.READ_WEEKLY_REPORT],
          },
          // {
          //   name: "Create Weekly Report",
          //   link: `${ROUTE_WEEKLY_TIME_REPORT}/create`,
          //   permissions: [PERMISSION_CODES.WRITE_WEEKLY_REPORT],
          // },
          {
            name: "View Monthly Report",
            link: ROUTE_MONTHLY_TIME_REPORT,
            permissions: [PERMISSION_CODES.READ_MONTHLY_REPORT],
          },
          // {
          //   name: "Create Monthly Report",
          //   link: `${ROUTE_MONTHLY_TIME_REPORT}/create`,
          //   permissions: [PERMISSION_CODES.WRITE_MONTHLY_REPORT],
          // },
          {
            name: "View Annual Report",
            link: ROUTE_ANNUAL_TIME_REPORT,
            permissions: [PERMISSION_CODES.READ_ANNUAL_REPORT],
          },
          // {
          //   name: "Create Annual Report",
          //   link: `${ROUTE_ANNUAL_TIME_REPORT}/create`,
          //   permissions: [PERMISSION_CODES.WRITE_ANNUAL_REPORT],
          // },
        ],
      },
      {
        name: "Manufacturing Planning",
        routes: [
          {
            name: "View Manufacturing Planning",
            link: ROUTE_MANUFACTURING_PLANNING,
            permissions: [PERMISSION_CODES.READ_MANUFACTURING_PLANNING],
          },
          {
            name: "Create Manufacturing Planning",
            link: `${ROUTE_MANUFACTURING_PLANNING}/create`,
            permissions: [PERMISSION_CODES.WRITE_MANUFACTURING_PLANNING],
          },
        ],
      },
    ],
  },
  {
    name: "Quality Management",
    routes: [
      {
        name: "Quality Control",
        routes: [
          {
            name: "View Incoming QC Report",
            link: `${ROUTE_INCOMING_MATERIAL}`,
            permissions: [PERMISSION_CODES.READ_INCOMING_MATERIAL],
          },
          {
            name: "Create Incoming QC Report",
            link: `${ROUTE_INCOMING_MATERIAL}/create`,
            permissions: [PERMISSION_CODES.WRITE_INCOMING_MATERIAL],
          },
          {
            name: "View Outgoing QC Report",
            link: `${ROUTE_OUTGOING_MATERIAL}`,
            permissions: [PERMISSION_CODES.READ_OUTGOING_MATERIAL],
          },
          {
            name: "Create Outgoing QC Report",
            link: `${ROUTE_OUTGOING_MATERIAL}/create`,
            permissions: [PERMISSION_CODES.WRITE_OUTGOING_MATERIAL],
          },
        ],
      },
    ],
  },
]
