/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import FilterBox from "./FilterBox"
import AsyncDropdown from "./input/AsyncDropdown"
import StandardTextField from "./input/StandardTextField"

function FilterDatatable({ columns: parentColumns, onChange }) {
  const [columns] = useState(parentColumns)
  const [values, setValues] = useState(() => {
    const keys = columns.map((c) => c.key)
    const obj = {}
    keys.forEach((k) => {
      obj[k] = null
    })

    return obj
  })

  useEffect(() => {
    const x = setTimeout(() => {
      if (onChange) {
        const obj = {
          search: [],
        }
        columns.forEach((col) => {
          if (typeof col.getOptionValue === "function") {
            obj[col.key] = col.getOptionValue(values[col.key])
          } else {
            obj[col.key] = values[col.key]
          }
          if (!obj[col.key]) {
            if (typeof obj[col.key] !== "boolean") {
              delete obj[col.key]
            }
          }
          if (col.type !== "list") {
            if (obj[col.key]) {
              obj.search.push({ column: col.key, value: obj[col.key] })
            }
            delete obj[col.key]
          }
        })
        onChange(obj)
      }
    }, 500)

    return () => {
      clearTimeout(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <FilterBox title="Column Filter">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
          gap: "8px",
        }}
      >
        {columns.map((c) => {
          if (c.type === "list") {
            return (
              <AsyncDropdown
                fetchData={c.fetchData}
                options={c.options}
                inputLabel={c.label}
                value={values[c.key]}
                onChange={(val) => {
                  const copy = { ...values }
                  copy[c.key] = val
                  setValues(copy)
                }}
                getOptionLabel={(opt) => c.getOptionLabel(opt)}
                getOptionSelected={(opt, val) => c.getOptionSelected(opt, val)}
                variant="outlined"
                style={{ marginBottom: 0, marginTop: 8 }}
              />
            )
          }
          return (
            <StandardTextField
              label={c.label}
              type={c.type}
              value={values[c.key]}
              onChange={(e) => {
                const copy = { ...values }
                copy[c.key] = e.target.value
                setValues(copy)
              }}
              margin="dense"
              variant="outlined"
              InputLabelProps={c.type === "date" ? { shrink: true } : undefined}
            />
          )
        })}
      </div>
    </FilterBox>
  )
}

export default FilterDatatable
