import { makeStyles } from "@material-ui/core"

export const useBaseFormStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
    flexGrow: 1,
  },
  fieldsWrapper: {
    "& > *": {
      display: "block",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: 10,
      display: "block",
    },
  },
  addButtonContainer: {
    position: "relative",
    maxWidth: 20,
    padding: 0,
  },
  addButton: {
    backgroundColor: "#439A97",
    color: "white",
    height: "100%",
    position: "absolute",
    top: "0",
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    "&:disabled": {
      backgroundColor: "gray",
      color: "#b3b3b3",
    },
    "&:hover:not(:disabled)": {
      backgroundColor: "#8e55f3",
    },
  },
  undefinedMaterialCell: {
    textAlign: "center",
  },
  selectField: {
    width: "100%",
  },
  switchLabel: {
    marginTop: 10,
  },
  formSectionTitle: {
    color: "#0449b0",
    fontSize: theme.typography.h6.fontSize,
    marginTop: "1rem",
  },
  tableWrapper: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  tableInputField: {
    padding: "0.4rem 0.4rem",
  },
  sectionCostLabel: {
    fontSize: theme.typography.body2.fontSize,
    textAlign: "right",
  },
  sectionCostDisplay: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "right",
  },
  rateValueField: {
    paddingTop: "0.9rem",
  },
  tableHeadRow: {
    "& th": {
      lineHeight: 1.3,
    },
  },
  tableContainer: {
    maxHeight: 440,
    marginBottom: 12,
  },
  table: {
    "& tr > td": {
      verticalAlign: "top",
      "&$rowNonInput": {
        verticalAlign: "middle",
      },
      "&$alignToInput": {
        paddingTop: 24,
      },
    },
  },
  materialDropdownField: {
    width: "100%",
    maxWidth: 230,
  },
  materialFieldInput: {
    maxWidth: "3em",
    minWidth: "3em",
  },
  materialDescriptionField: {
    marginBottom: "10px",
    width: "100%",
    minWidth: 300,
  },
  materialButtonAction: {
    paddingTop: 16,
  },
  errorLabel: {
    lineHeight: 1.2,
  },
  rowNonInput: {},
  alignToInput: {},
  "@global": {
    "& .MuiTableCell-root": {
      padding: "6px 8px",
    },
    "#material-table": {
      "& .MuiTableCell-sizeSmall": {
        paddingRight: 6,
        paddingLeft: 6,
      },
      "& $addButtonContainer": {
        minWidth: 40,
        padding: 0,
      },
      "& .MuiTableCell-footer": {
        position: "sticky",
        bottom: 0,
        background: "white",
        borderTop: "thin solid #bfbfbf",
      },
    },
  },
}))

export const GENERAL_BLANK_FORM = {
  estimationNumber: "",
  estRefNumber: null,
  rfqId: null,
  leadTime: null,
  customerId: null, // FK to customer ID
  productName: "",
  estimationDate: "",
  //   comment: `
  //    # DESCRIPTION Estimation  :
  //   1	Number of cavity                               			:
  //   2	 Runner type                                       			:
  //   3	Hot runner system                            			:
  //   4	Gate type                                           			:
  //   5	Clamping force                                  			:
  //   6	Material resin                                      			:
  //   7	Shrinkage                                            			:
  //   8	Second procces                                  			:
  //   9	Mold system                                        			:
  //   10	Engraving on cavity                           			:
  //   11	Engraving on core                              			:
  //   12	Ejection methode                               			:
  //   13	Undercut treatment                           			:
  //   14	Material. MOLD BASE                     			:
  //   15	Material TOP PLATE                           			:
  //   16	Material BOTTOM PLATE                  			:
  //   17	Material CAVITY BASE                     			:
  //   18	Material CAVITY INSERT                  			:
  //   19	Material CORE BASE                        			:
  //   20	Material CORE INSERT                     			:
  //   21	Material SLIDER /  INSERT                			:
  //   22	Material EJECTOR PLATE /BLOCK   			:
  //   23	Material HR PLATE                             			:
  //   24	Material RETAINER PLATE                 			:
  //   25	Material SPACER                                 			:
  //   26	Etching / SHIBO                                   			:
  //   27	Surface Finishing                                 			:
  //   28	Others                                                    		:

  //   + Only Once a machining Process for target estimation quotation.
  //   # REMARK :
  //    1. Fix data Received and mayor change, the quottaion will be revised if there is a significant change such as the material dimension, will be re estimate.
  //    2. Quotation validaty : 30 Day
  //    3. Delivery Schedule  : 60 Day ( since Received PO, final data & Mould spec)
  //    4. Payment Schedule   : 40% after PO  & 30 % after MOLD DELIVERY  & 30% after TRIAL T1
  // `,
  comment: `
#TERMS & CONDITION :
1. Not Included MOLD Data
2. The Price Excluded VAT
3. Payment Term Transfer, 1 Month After Receiving Invoice
4. Validity quotation : 30 days
`,
  approvedBy: "",
  checkedBy: "",
  preparedBy: "",
  productionHourReducer: 0,
  tonage: null,
  cavity: null,
  hotRunnerSystem: null,
}

export const MATERIAL_BLANK_FORM = {
  materialCosts:
    [
      {
        id: 0, // ID from estimation-quotation material table; null for new items
        materialId: 0, // FK to material cost master data
        description: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 0,
        sequence: null,
      },
    ] && [], // &&-ed with [] to reset the declared materialCosts content above
  materialCostHandling: 0,
}

export const PROCESS_BLANK_FORM = {
  processCosts:
    [
      {
        id: 0,
        processId: 0, // FK to corresponding process cost
        time: 0,
      },
    ] && [],
  processCostHandling: 0,
}

export const ADDITIONAL_BLANK_FORM = {
  additionalCosts:
    [
      {
        id: 0,
        process: "",
        quantity: 0,
        cost: 0,
      },
    ] && [],
  additionalCostHandling: 0,
}

export const OUTSOURCING_BLANK_FORM = {
  outsourcingCosts: [{ id: 0, process: "", quantity: 0, cost: 0 }] && [],
  outsourcingCostHandling: 0,
}

export const ADDITIONAL_BLANK_FORM_NEW = {
  additionalCostsNew:
    [
      {
        id: 0,
        process: "",
        quantity: 0,
        cost: 0,
      },
    ] && [],
  additionalCostHandling: 0,
}

export const LWT_RATE_BLANK_FORM = {
  status: "enter",
  length: 1,
  width: 1,
  height: 1,

  length2: null,
  width2: null,
  height2: null,

  length3: null,
  width3: null,
  height3: null,

  length4: null,
  width4: null,
  height4: null,

  rateInId: null, // ID to currency
  rateOutId: null, // ID to currency
  rateInValue: 1,
  rateOutValue: 1,

  design: "",
  attachment: "",
  scheduleAttachment: "",
}

export const ESTIMATION_BLANK_FORM = {
  ...GENERAL_BLANK_FORM,
  ...MATERIAL_BLANK_FORM,
  ...PROCESS_BLANK_FORM,
  ...ADDITIONAL_BLANK_FORM,
  ...OUTSOURCING_BLANK_FORM,
  ...LWT_RATE_BLANK_FORM,
}

export const MARGINS = [5, 10, 15, 20, 25, 30]

export const usePrintStyles = makeStyles({
  logo: {
    width: "12mm",
  },
  pageHeaderContent: {
    width: "180mm",
    display: "flex",
    alignItems: "center",
    fontWeight: "bolder",
    fontStyle: "italic",
  },
  tableTitle: {
    fontWeight: "bold",
    marginBottom: "2mm",
  },
  page: {
    fontSize: "10pt",
    lineHeight: 1.1,
    "& table": {
      textAlign: "left",
    },
    "& th, & td, & tr": {
      // Remove row space by default
      marginBottom: 0,
      paddingBottom: 0,
    },
    "& table.table-headleft th": {
      paddingRight: "5mm",
    },
    "& table.table-headleft td": {
      textAlign: "right",
    },
    "& #general td": {
      textAlign: "left",
    },
    "& .table-long": {
      "& thead > * > *": {
        borderTop: "0.3mm solid black",
        borderBottom: "0.3mm solid black",
        padding: "1.1mm 2.6mm",
      },
      "& tbody > * > *": {
        padding: "1.05mm 2.6mm",
      },
      "& tbody > *:last-child > *": {
        borderBottom: "0.3mm solid black",
      },
    },
    "& .table-handling": {
      paddingRight: "2.6mm",
      pageBreakInside: "avoid",
      marginBottom: "3mm",
    },
    "& .table-handling tbody": {
      pageBreakInside: "avoid",
    },
    "& .title": {
      marginTop: 0,
      fontSize: "inherit",
    },
  },
  totalCostsTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
    "& * > * > :nth-child(1)": {
      textAlign: "left",
    },
    "& tbody > *:last-child > *": {
      paddingBottom: "1.7mm",
    },
    "& tfoot > *:first-child > *": {
      borderTop: "0.3mm solid black",
      paddingTop: "1.7mm",
    },
  },
  marginTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
  },
  approvedBox: {
    border: "0.3mm solid black",
    height: "15.5mm",
  },
  noBorder: {
    border: "none",
    width: "100%",
    padding: 0,
    // margin: "-10mm",
  },
})
